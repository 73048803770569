$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

/* colors */
$primary: #007bff;
$secondary: #041436;
$saphire: #072046;
$success: rgb(44, 102, 44);

$white: #ffffff;
$offwhite: #e9e9e9;
$black: #000000;
$grey: #676e7c;

$err-red: #eb0f0f;

.text-lbue {
  color: $primary;
}
