@import "../../global/global.scss";

.header {
    &-primary {
        background-color: $primary;
        padding: rem(8) rem(12);
        position: relative;
        z-index: 90;

        &.header-sticky {
            position: fixed;
            width: 100%;
            top: 0;
            z-index: 112;
        }

        &::before {
            position: absolute;
            content: '';
            height: 100%;
            width: 35%;
            background: #fff;
            left: -110px;
            top: 0;
            transform: skewX(45deg);
            z-index: 2;

            @include media-breakpoint-up(xs) {
                width: 85%;
                height: 100%;
            }

            @include media-breakpoint-up(md) {
                width: 48%;
                height: 100%;
            }
            @include media-breakpoint-up(lg) {
                height: 100%;
                width: 35%;
            }
            
        }
    }
    &-logo {
        position: relative;
        z-index: 3;
        max-width: rem(160);

        img {
            width: 100%;
        }
    }
    &-nav {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;

    }
    &-main-menu {
        padding: rem(12) rem(0);
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;

        .navbar {
            &-toggler {
                border: rem(2) solid #f8f9fa;
                border-radius: 0px;
                color: #fff;
                outline: none;
                box-shadow: none;
    
                svg {
                    color: $white;
                    opacity: 1;
                }
    
                @include media-breakpoint-up(xs) {
                    order: 2
                }
        
                @include media-breakpoint-up(lg) {
                    order: 1;
                }
            }

            &-collapse {
                @include media-breakpoint-up(xs) {
                    position: absolute;
                    left: 0;
                    top: rem(84);
                    width: 100%;
                    background: $white;
                    z-index: 99;
                    box-shadow: rem(0) rem(0) rem(3) $offwhite;
                }

                @include media-breakpoint-up(md) {
                    position: absolute;
                    left: 0;
                    top: rem(90);
                    box-shadow: none;
                }

                @include media-breakpoint-up(lg) {
                    position: relative;
                    left: auto;
                    top: auto;
                    width: auto;
                    background: transparent;
                }

                .navbar-nav {
                    @include media-breakpoint-up(xs) {
                        padding: rem(0);
                    }
                }

                .nav {
                    &-link {
                        text-transform: uppercase;
                        position: relative;
        
                        @include media-breakpoint-up(xs) {
                            font-size: rem(14);
                            font-weight: 500;
                            margin: 0;
                            color: $black;
                            padding: rem(10) rem(15);
                            border-bottom: rem(1) solid #e5e5e5;

                            &:hover {
                                background-color: $primary;
                                color: $white;
                            }
                        }
        
                        @include media-breakpoint-up(lg) {
                            font-size: rem(16);
                            font-weight: 500;
                            padding: rem(0);
                            margin: 0 rem(12);
                            color: $white;
                            border: none;
                        }
        
                        @include media-breakpoint-up(xxl) {
                            font-size: rem(16);
                            margin: 0 rem(12);
                        }
                    }
                }
            }

        }
    }
    .btn-quote {
        background-color: $white;
        color: $primary;
        padding: rem(12) rem(35);
        border: 2px solid $primary;
        border-radius: rem(25);
        text-decoration: none;
        text-transform: uppercase;
        font-size: rem(14);
        font-weight: 600;
        box-shadow: 0px 0px 3px $primary ;

        &:hover {
            background-color: $primary;
            color: $white;
            border: 2px solid $white;
        }

        @include media-breakpoint-up(xs) {
            order: 1;
            margin-right: rem(15);
            display: none;
            position: absolute;
            right: 60px;
        }
        @include media-breakpoint-up(md) {
            order: 1;
            margin-right: rem(15);
            display: block;
        }

        @include media-breakpoint-up(lg) {
            position: initial;
            right: auto;
            order: 2;
            margin-right: rem(0);
        }

        @include media-breakpoint-down(xl) {
            font-size: rem(12);
            padding: rem(12) rem(30);
            border-radius: rem(22);
        }
    }
}