@import "../global/global.scss";
.terms {
    &-section {
        @include media-breakpoint-up(xs) {
            padding-top: rem(60);
            padding-bottom: rem(60);
        }
        @include media-breakpoint-up(md) {
            padding-top: rem(120);
            padding-bottom: rem(120);
        }

        .title {
            font-size: 32px;
            font-weight: 600;
            text-align: center;
        }
    }
    &-item {
        margin: rem(30) rem(0);

        h4 {
            font-size: 22px;
            font-weight: 600;
            margin-bottom: 15px;
        }
        p {
            font-size: 15px;
            font-weight: 400;
            line-height: 26px;
        }
    }
}