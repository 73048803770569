@import "../../global/global.scss";
.features {
    position: relative;
    z-index: 11;

    .expr {
        &-row {
            margin-top: rem(150);
            margin-bottom: rem(-80);
            background-color: $primary;
            padding: rem(30) rem(30);

            @include media-breakpoint-up(xs) {
                margin-top: rem(50);
            }
    
            @include media-breakpoint-up(lg) {
                margin-top: rem(150);
            }
        }
        &-item {
            width: 100%;
            background-color: $white;
            display: flex;
            align-items: center;
            padding: rem(20) rem(0);
            margin: 0;
    
            svg {
                width: rem(40);
                height: rem(40);
                color: $primary;

                @include media-breakpoint-up(xs) {
                    width: rem(40);
                    height: rem(40);
                }
        
                @include media-breakpoint-up(lg) {
                    width: rem(30);
                    height: rem(30);
                }
                @include media-breakpoint-up(xl) {
                    width: rem(40);
                    height: rem(40);
                }
            }
            h3 {
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 5px;
                
                @include media-breakpoint-up(xs) {
                    font-size: rem(16);
                }
        
                @include media-breakpoint-up(lg) {
                    font-size: rem(16);
                }
                @include media-breakpoint-up(xl) {
                    font-size: rem(20);
                }
            }
            p {
                font-size: rem(16);
                color: $grey;

                @include media-breakpoint-up(xs) {
                    font-size: rem(14);
                }
                @include media-breakpoint-up(xl) {
                    font-size: rem(16);
                }
            }
        }
    }
}