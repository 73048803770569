@import "../../global/global.scss";
.intro {
    text-align: center;
    margin: rem(155) rem(0) rem(60);
    position: relative;
    z-index: 111;

    @include media-breakpoint-up(xs) {
        margin: rem(45) rem(0) rem(40);
    }

    @include media-breakpoint-up(md) {
        margin: rem(155) rem(0) rem(60);
    }

    &-title {
        color: $secondary;
        font-size: rem(50);
        font-weight: 700;
        margin: rem(20) rem(0);

        @include media-breakpoint-up(xs) {
            font-size: rem(28);
        }

        @include media-breakpoint-up(md) {
            font-size: rem(50);
        }
    }
    &-desc {
        max-width: rem(800);
        margin: rem(0) auto;
        color: $secondary ;
    }
    &-row {
        margin: rem(0);
        @include media-breakpoint-up(xs) {
            margin: rem(0) rem(-15);
            display: flex;
            justify-content: center;
        }

        @include media-breakpoint-up(lg) {
            margin: rem(0);
            margin: rem(0) rem(-15);
            display: flex;
            justify-content: flex-start;
        }

        & > div {
            padding: rem(0);

            @include media-breakpoint-up(xs) {
                padding: rem(0) rem(10);
            }
    
            @include media-breakpoint-up(lg) {
                padding: rem(0);
            }
        }
    }
    &-column {
        &:nth-child(2) {
            @include media-breakpoint-up(xs) {
                order: 3;
            }

            @include media-breakpoint-up(lg) {
                order: 2;
            }
        }

        &:nth-child(3) {
            @include media-breakpoint-up(xs) {
                order: 2;
            }

            @include media-breakpoint-up(lg) {
                order: 3;
            }
        }
    }
    &-boxes {
        margin: rem(60) rem(0);

        .intro-box {
            @include media-breakpoint-up(xs) {
                margin-bottom: rem(20);
            }

            @include media-breakpoint-up(lg) {
                margin-bottom: rem(0);
            }

            &.dark {
                background: $secondary;
                color: $white;
                padding: rem(64) rem(24) rem(67);

                & > p {
                    margin: auto rem(25);
                }

                .intro-icon {
                    svg {
                        width: rem(50);
                        height: rem(50);
                        color: $primary;
                        margin-bottom: rem(20);
                    }
                }
            }

            &.light {
                background: $primary;
                color: $white;
                padding: rem(64) rem(24) rem(67);
                position: relative;
                z-index: 5;
               
                &::before {
                    position: absolute;
                    content: "";
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background: $primary;
                    z-index: -1;

                    @include media-breakpoint-up(xs) {
                        transform: scale(1);
                    }
        
                    @include media-breakpoint-up(lg) {
                        transform: scale(1.2);
                    }
                }

                .intro-icon {
                    svg {
                        width: rem(50);
                        height: rem(50);
                        color: $white;
                        margin-bottom: rem(20);
                    }
                }
            }
            
        }
    }
}