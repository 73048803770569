@import "../../global/global.scss";
.projects {

    @include media-breakpoint-up(xs) {
        padding: rem(20) rem(0);
    }

    @include media-breakpoint-up(md) {
        padding: rem(100) rem(0);
    }

    &-container {
        max-width: 100%;
        padding: rem(20) rem(40);

        @include media-breakpoint-up(xs) {
            padding: rem(20) rem(10);
        }
    
        @include media-breakpoint-up(md) {
            padding: rem(20) rem(40);
        }
    }

    &-top {
        text-align: center;
        padding: rem(30) auto;

        .title {
            font-size: rem(48);
            font-weight: 700;
            color: $secondary;

            @include media-breakpoint-up(xs) {
                font-size: rem(28);
            }
        
            @include media-breakpoint-up(md) {
                font-size: rem(48);
            }
        }
        p {
            font-size: rem(16);
            line-height: rem(28);
            color: $grey;
            padding-top: rem(20);
            padding-bottom: rem(60);
            max-width: rem(600);
            margin: 0 auto;
        }
    }

    .slick {
        &-list {
            margin: rem(0) rem(-20);

            @include media-breakpoint-up(xs) {
                margin: rem(0);
            }
        
            @include media-breakpoint-up(md) {
                margin: rem(0) rem(-20);
            }
        }
        &-slide {
            padding: rem(0) rem(20);

            @include media-breakpoint-up(xs) {
                padding: rem(0);
            }
        
            @include media-breakpoint-up(md) {
                padding: rem(0) rem(20);
            }
        }
        &-dots {
            position: absolute;
            bottom: rem(-60);
            padding: rem(0) rem(30);
            margin: 0 auto;

            li {
                width: rem(10);
                height: rem(10);
                background: $primary;
                border-radius: 50%;
                transition: all 0.3s ease; 

                &.slick-active {
                    background: $secondary;
                    width: rem(25);
                    border-radius: rem(10) rem(10);
                }

                button {
                    width: rem(14);
                    height: rem(14);

                    &:before {
                        content: "";
                    }
                }
            }
            
        }
    }

    .slider-item {
        position: relative;
        overflow: hidden;

        .item {
            &-thumb {
                img {
                    width: 100%;
                    transition: all 0.3s ease-out;
                    transform: scale(1);
                }
            }
            &-detail {
                position: absolute;
                left: rem(0);
                top: rem(0);
                width: 100%;
                height: 100%;
                padding: rem(30) rem(20);
                text-align: center;
                background-color: rgba(0, 0, 0, 0.8);
                transition: all 0.3s ease-out 0s;
                opacity: 0;

                .center-align {
                    position: absolute;
                    top: 50%;
                    left: rem(0);
                    transform: translateY(-50%);
                }

                a {
                    color: $primary;
                    font-size: rem(14);
                    font-weight: 500;
                    margin-top: rem(17);
                    text-decoration: none;

                    svg {
                        color: inherit;
                        fill: inherit;
                        width: rem(20);
                        height: rem(16);
                    }
                }
            }
            &-title {
                color: $white;
                font-size: rem(20);
                font-weight: 600;
            }
            &-desc {
                color: $white;
                font-size: rem(14);
                line-height: rem(24);

                @include media-breakpoint-up(xs) {
                    padding: rem(0) rem(14);
                }
                @include media-breakpoint-up(sm) {
                    padding: rem(0) rem(20);
                }

            }
            &-bottom {
                h3 {
                    font-size: rem(20);
                    font-weight: 600;
                    text-transform: uppercase;
                    text-align: center;
                    margin: rem(10) rem(0);
                }
            }
        }

        &:hover {
            .item {
                &-detail {
                    opacity: 1;
                }
                &-thumb {
                    img {
                        transform: scale(1.2);
                    }
                }
            }
        }
    }
}