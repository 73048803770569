@import "../../global/global.scss";
.checklist {
    &-section {
        position: relative;
        z-index: 4;
        padding-top: rem(100);
        padding-bottom: rem(100);
        background-attachment: fixed;

        @include media-breakpoint-up(xs) {
            padding-top: rem(60);
            padding-bottom: rem(100);
        }
    
        @include media-breakpoint-up(md) {
            padding-top: rem(100);
            padding-bottom: rem(100);
        }

        &::before {
            position: absolute;
            content: "";
            z-index: 5;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: $secondary;
            opacity: 0.95;
        }
    }
    &-content {
        position: relative;
        z-index: 5;
        opacity: 1;
        text-align: center;

        .title {
            font-size: rem(48);
            font-weight: 700;
            color: $white;

            @include media-breakpoint-up(xs) {
                font-size: rem(28);
            }
        
            @include media-breakpoint-up(md) {
                font-size: rem(48);
            }
        }
        .desc {
            font-size: rem(16);
            color: $white;
            opacity: 0.7;
            max-width: rem(600);
            margin: 0 auto;

            @include media-breakpoint-up(xs) {
                font-size: rem(14);
            }
        
            @include media-breakpoint-up(md) {
                font-size: rem(16);
            }
        }
    }

    &-body {
        text-align: left;
        padding-top: rem(80);
    }

    &-item {
        position: relative;
        padding-left: rem(40);
        margin-bottom: rem(40) ;

        .checklist-item {
            &-icon {
                position: absolute;
                left: 0;
                top: 0;
                color: $primary;
                font-size: rem(18);
    
                svg {
                    width: rem(22);
                    height: rem(22);

                    path {
                        fill: $primary;
                    }
                }
            }
            &-title {
                color: #fff;
                font-size: rem(20);
                padding-bottom: rem(15);
            }
            &-desc {
                color: #fff;
                font-size: rem(15);
                opacity: .7;
            }
        }
    }

}