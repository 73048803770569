@import "../../global/global.scss";
.service {
    margin-bottom: rem(60);
    padding-top: rem(182);
    padding-bottom: rem(129);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: relative;
    z-index: 10;

    @include media-breakpoint-up(xs) {
        padding-top: rem(100);
        padding-bottom: rem(80);
    }

    @include media-breakpoint-up(md) {
        padding-top: rem(182);
        padding-bottom: rem(129);
    }

    &::before {
        position: absolute;
        content: '';
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        opacity: .90;
        background-color: $secondary;
        z-index: 11;
    }

    &-container {
        position: relative;
        z-index: 12;
        text-align: center;
    }

    &-upper {
        h3 {
            font-size: rem(48);
            color: $white;

            @include media-breakpoint-up(xs) {
                font-size: rem(30);
            }
        
            @include media-breakpoint-up(md) {
                font-size: rem(48);
            }
        }
        p {
            font-size: rem(16);
            color: $white;
            opacity: .7;
            padding-top: rem(30);
            padding-bottom: rem(60);
            max-width: rem(600);
            margin: 0 auto;

            @include media-breakpoint-up(xs) {
                font-size: rem(14);
                padding-left: rem(20);
                padding-right: rem(20);
            }
        
            @include media-breakpoint-up(md) {
                font-size: rem(16);
                padding-left: rem(0);
                padding-right: rem(0);
            }
        }
    }

    &-row {
        justify-content: center;
        margin: 0 rem(-25);

        @include media-breakpoint-up(xs) {
            margin: rem(0) rem(0)
        }
    
        @include media-breakpoint-up(md) {
            margin: 0 rem(-25);
        }

        & > div {
            padding-left: rem(25);
            padding-right: rem(25);
            display: flex;

            @include media-breakpoint-up(xs) {
                padding-left: rem(0);
                padding-right: rem(0);
            }
        
            @include media-breakpoint-up(md) {
                padding-left: rem(25);
                padding-right: rem(25);
            }
        }
    }

    &-item {
        padding: rem(35) rem(30) rem(60);
        border-radius: 5px;
        border: rem(1) solid $offwhite;
        -webkit-transition: all 0.3s ease-out 0s;
        -moz-transition: all 0.3s ease-out 0s;
        -ms-transition: all 0.3s ease-out 0s;
        -o-transition: all 0.3s ease-out 0s;
        transition: all 0.3s ease-out 0s;
        background: $white;
        position: relative;
        overflow: hidden;
        z-index: 5;
        margin-bottom: rem(40);
        flex: 1;

        @include media-breakpoint-up(xs) {
            margin: rem(20) rem(0)
        }
    
        @include media-breakpoint-up(md) {
            margin: rem(20) rem(0);
        }
        

        &:hover {
            background-color: $primary;
            border-color: $primary;

            h3, p {
              color: $white;
            }
            .service-icon {
                svg {
                    color: $white;
                    fill: $white;
                }
            }
        }

        h3 {
            font-size: rem(20);
            font-weight: 700;
            padding-top: rem(10);
            padding-bottom: rem(7);
        }
        p {
            font-size: rem(15);
            font-weight: 400;
            color: $grey;
        }

        .service-icon {
            svg {
                height: rem(54);
                width: rem(54);
                color: $primary;
                fill: $primary
            }
        }
    }
}