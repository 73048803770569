@import "../../global/global.scss";
.quote {
    &-section {
        position: relative;
        z-index: 6;
        margin-top: rem(-105);
        padding-bottom: rem(122);

        @include media-breakpoint-up(xs) {
            padding-bottom: rem(42);
        }

        @include media-breakpoint-up(md) {
            padding-bottom: rem(122);
        }
    }
    &-row {
        position: relative;
        margin: 0;
        box-shadow: 0px 5px 25px 0px rgb(0 0 0 / 7%);

        &.no-gutters {
            margin-right: 0;
            margin-left: 0;

            & > div {
                padding-right: rem(0);
                padding-left: rem(0);
            }

        }

        .form-column {
            padding: 65px 50px 50px;
            background: $white; 
            position: relative;

            .title {
                font-size: 24px;
                padding-bottom: 10px;
            }
            p {
                font-size: 16px;
                color: $grey;
            }
            .form-group {
                margin-bottom: rem(20);

                input.field-error, textarea.field-error {
                    border: 1px solid $err-red;
                }
                & > .error-msg {
                    margin-top: rem(10);
                    color: $err-red;
                    font-size: rem(14);
                    display: none;

                    &:nth-child(2) {
                        display: block;
                    }
                }
            }
            .success-text {
                color: $success;
                font-size: rem(16);
                font-weight: 600;
            } 
            .error-text {
                color: $err-red;
                font-size: rem(16);
                font-weight: 600;
            }

            input, textarea {
                box-shadow: none;
                outline: none;
                color: #676e7c;
                font-size: rem(15);
                background: rgba(1, 14, 42, 0.031);
                border-radius: rem(5);
                padding-left: rem(25);
                line-height: rem(35);
            }
            input:focus, textarea:focus {
                border: rem(1) solid $primary;
            } 
            .checkbox-inline {
                display: flex;
                align-items: center;

                p {
                    margin-bottom: rem(0);
                    margin-left: rem(10);
                }
            }

            .btn-send {
                background-color: $primary;
                border: rem(2) solid $primary;
                text-transform: uppercase;
                color: $white;
                padding: rem(0) rem(43);
                line-height: rem(50);
                border-radius: rem(30);
                font-size: rem(15);
                font-weight: 600;

                &:hover {
                    background-color: $white;
                    color: $primary;
                    border: rem(2) solid $primary;
                }
            }
        }

        .contact {
            &-column {
                background: $primary;
                height: 100%;
                padding: 65px 50px 50px;

                h3 {
                    color: #fff;
                    font-size: 24px;
                    padding-bottom: 10px;
                }
                p {
                    font-size: 16px;
                    font-weight: 400;
                    color: #fff;  
                }
            }
            &-item {
                position: relative;
                padding-left: rem(70);

                .contact {
                    &-icon {
                        top: 0;
                        left: 0;
                        position: absolute;
                        color: #fff;
                        height: 40px;
                        width: 40px;
                        border-radius: 50%;
                        text-align: center;
                        line-height: 42px;
                        background-color: rgba(255, 255, 255, 0.102); 

                        svg {
                            height: rem(18);
                            width: rem(18);
                            color: $white;
                            fill: $white;
                        }
                    }

                    &-detail {
                        h4 {
                            font-size: rem(16);
                            font-weight: normal;
                            color: #fff;
                            opacity: .8;
                            padding-bottom: 5px; 
                        }
                        p {
                            color: #fff;
                            font-size: 15px;
                            line-height: 30px;
                        }
                    }
                }
            }


        }
    }
}
.success-msg {
    background: $white;
    border-left: rem(3) solid $green;
    padding: rem(10) rem(20);
    box-shadow: rem(0) rem(0) rem(5) $offwhite;
}

.error-alert {
    background: $white;
    border-left: rem(3) solid $err-red;
    padding: rem(10) rem(20);
    box-shadow: rem(0) rem(0) rem(5) $offwhite;
}