@import "../../global/global.scss";
.footer {
    &-section {
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        @include media-breakpoint-up(xs) {
            font-size: rem(16);
        }

        @include media-breakpoint-up(md) {
            font-size: rem(16);
        }

        &::before {
            position: absolute;
            content: "";
            left: rem(0);
            top: rem(0);
            width: 100%;
            height: 100%;
            background-color: $secondary;
            opacity: 0.95;
        }
    }
    &-logo {
        width: rem(200);
        margin: 0 auto;

        img {
            width: 100%;
        }
    }
    &-top {
        position: relative;
        opacity: 1;
        text-align: center;
        padding: rem(50) rem(0);

        @include media-breakpoint-up(xs) {
            padding: rem(40) rem(0);
        }

        @include media-breakpoint-up(md) {
            padding: rem(50) rem(0);
        }

        .footer-desc {
            color: #fff;
            opacity: .7;
            padding: 45px 45px 60px;
            max-width: rem(720);
            margin: 0 auto;
        }
    }
    &-social {
        display: flex;
        align-items: center;
        padding: 0;
        justify-content: center;

        li {
            list-style: none;
            margin: rem(0) rem(10);

            a {
                text-decoration: none;

                svg {
                    fill: $white;
                    color: $white;
                    height: rem(24);
                    width: rem(24);
                }
            }
            &:hover {
                svg {
                    fill: $primary;
                    color: $primary;
                }
            }
        }
    }
    &-bottom {
        position: relative;
        opacity: 1;
        border-top: 1px solid rgba(255, 255, 255, 0.102);
        padding: rem(20) rem(0);

        p {
            font-size: 15px;
            color: #fff;
            opacity: .5;
            text-align: left;

            @include media-breakpoint-up(xs) {
                text-align: center;
            }
    
            @include media-breakpoint-up(lg) {
                text-align: left;
            }
        }
    }
    &-pages {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @include media-breakpoint-up(xs) {
            justify-content: center;

            .disp-xs-none {
                display: none;
            }
        }

        @include media-breakpoint-up(lg) { 
            .disp-xs-none {
                display: block;
            }
        }

        @include media-breakpoint-up(lg) {
            justify-content: flex-end;
        }
        
        li {
            margin: rem(0) rem(10);
            list-style: none;

            a {
                color: #fff;
                opacity: .7;
                font-size: rem(15);
                font-weight: 500;
                text-decoration: none;
            }
            &:hover {
                a {
                    color: $primary;
                }
            }
        }

    }
}