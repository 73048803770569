@import "../../global/global.scss";
.review {
    &-section {

    }
    &-content {
        text-align: center;

        .title {
            font-size: rem(48);
            font-weight: 700;
            color: $secondary;

            @include media-breakpoint-up(xs) {
                font-size: rem(28);
            }
    
            @include media-breakpoint-up(md) {
                font-size: rem(48);
            }
        }
        .desc {
            color: $secondary;
            padding-top: 20px;
            line-height: 32px;
            max-width: rem(600);
            margin: 0 auto;

            @include media-breakpoint-up(xs) {
                font-size: rem(16);
            }
    
            @include media-breakpoint-up(md) {
                font-size: rem(16);
            }
        }
    }
    &-slider {
        margin: rem(100) rem(0);

        .slick {
            &-list {
                box-shadow: 0px 0px 8px rgb(0 0 0 / 10%);
            }
            &-dots {
                position: absolute;
                bottom: rem(-45);

                li {

                    &.slick-active {
                        button {
                            opacity: 1;
                        }
                    }

                    button {
                        display: inline-block;
                        width: rem(24);
                        height: rem(5);
                        padding: rem(0);
                        background: $primary;
                        opacity: 0.3;

                        &::before {
                            content: "";
                        }
                    }
                }
            }
        }
    }
    &-item {
        position: relative;
        padding: rem(5) rem(100)  rem(5) rem(200);
        box-shadow: 0px 5px 25px 0px rgb(0 0 0 / 10%);
        cursor: pointer;
        z-index: 10;
        overflow: hidden;

        @include media-breakpoint-up(xs) {
            padding: rem(5) rem(20)  rem(5) rem(20);
        }
    
        @include media-breakpoint-up(md) {
            padding: rem(5) rem(100)  rem(5) rem(200);
        }

        &::before {
            position: absolute;
            font-family: "Font Awesome";
            content: attr(data-icon);
            color: #fff;
            right: rem(-100);
            top: rem(-100);
            transform: rotate(45deg);
            width: rem(190);
            height: rem(190);
            -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
            clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
            background-color: $primary;
            
        }

        .client {
            &-image {
                width: rem(200);
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);

                @include media-breakpoint-up(xs) {
                    width: rem(0);
                }
            
                @include media-breakpoint-up(md) {
                    width: rem(200);
                }


                img {
                    width: 100%;
                }
            }
            &-detail {
                padding: rem(16) rem(50);
            }
            &-name {
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 5px;
            }
            &-rating {
                color: #ffa200;
                font-size: 14px;
                margin-bottom: rem(20);

                svg {
                    fill: #ffa200;
                    color: #ffa200;
                    height: rem(18);
                    width: rem(18);

                }
            }
            &-rank {
                color: $primary;
                font-size: 15px;
                position: relative;
                padding-left: 55px;
                margin-top: 25px;

                &::before {
                    position: absolute;
                    content: '';
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 40px;
                    height: 1px;
                    background: #b2b2b2;
                }
            }
        }
    }

    &-icon {
        position: absolute;
        right: rem(20);
        top: rem(20);
        color: $white;
        font-size: rem(30);
    }

}