@import "../../global/global.scss";

.brandparent {
    text-align: center;
    .brandinner {
        text-align: center;
    }
    .brand-card {
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .title {
        font-weight: 700;
        color: $secondary;

        @include media-breakpoint-up(xs) {
            font-size: rem(28);
        }

        @include media-breakpoint-up(md) {
            font-size: rem(48);
        }
    }
    .sub-title {
        padding-top: rem(20);
        padding-bottom: rem(60);
        font-size: rem(16);
        line-height: rem(28);
        color: $grey;
        max-width: rem(600);
        margin: 0 auto;
        width: 100%;
    }
}